<template>
    <ViewTitle :title="title" class="detail-viewtitle">
        <template v-slot:buttons>
            <div class="flex control-button">
                <Button
                    buttonWord="回保單查詢"
                    buttonStyle="hollow"
                    @click="backToInsurance"
                />
                <Button
                    v-if="mode === '1'"
                    :buttonWord="`${
                        insServicesLength > 0 ? '通知內容' : '無通知內容'
                    }`"
                    buttonStyle="blue-hollow"
                    :disabled="insServicesLength === 0"
                    @click="
                        ;(isShowEventModel = true),
                            $setGaEvent('showEventModel', 'click-Button')
                    "
                />
            </div>
        </template>
    </ViewTitle>
    <div class="menu-header">
        <div class="menu-wrapper" ref="menu">
            <div class="menu">
                <div
                    v-text="'保單內容/商品'"
                    class="menu-item"
                    :class="{ active: page === 'product' }"
                    @click="page = 'product'"
                />
                <div
                    v-if="mode === '1'"
                    v-text="'影像檔'"
                    class="menu-item"
                    :class="{ active: page === 'image' }"
                    @click="page = 'image'"
                />

                <div
                    v-if="mode === '1' && $hasPermission('commissionList')"
                    v-text="'佣金明細'"
                    class="menu-item"
                    :class="{ active: page === 'commission' }"
                    @click="page = 'commission'"
                />
                <div
                    v-if="mode === '1'"
                    v-text="'服務權紀錄'"
                    class="menu-item"
                    :class="{ active: page === 'sales' }"
                    @click="page = 'sales'"
                />
                <div
                    v-if="mode === '1'"
                    v-text="'備註/記事'"
                    class="menu-item"
                    :class="{ active: page === 'note' }"
                    @click="page = 'note'"
                />
            </div>
        </div>
        <div id="detail-menu-teleport" />
    </div>
    <div class="mobile-container">
        <InsuranceProduct
            ref="product"
            class="mobile-page"
            :class="{ active: page === 'product' }"
            :dataList="editedDataList"
            :isLoading="isLoadingTable"
            v-model="showEditModel"
        />
        <InsuranceImage
            v-if="mode === '1'"
            ref="image"
            class="mobile-page"
            :class="{ active: page === 'image' }"
            :dataList="dataList"
            :isLoading="isLoadingTable"
            :isDefaultClose="false"
        />
        <InsuranceCommission
            v-if="mode === '1' && $hasPermission('commissionList')"
            ref="commission"
            class="mobile-page"
            :class="{ active: page === 'commission' }"
            :isCurrenPage="page === 'commission'"
            :insId="insId"
            :source="$route.params.source"
            :isPageLoading="isLoadingTable"
            :manCode="manCode"
        />
        <InsuranceSales
            v-if="mode === '1'"
            ref="sales"
            class="mobile-page"
            :class="{ active: page === 'sales' }"
            :dataList="dataList"
            :isLoading="isLoadingTable"
        />
        <InsuranceRemarkNote
            v-if="mode === '1'"
            ref="note"
            class="mobile-page"
            :class="{ active: page === 'note' }"
            :dataList="editedDataList"
            :isLoading="isLoadingTable"
            v-model="showEditModel"
        />
    </div>
    <InsuranceDetailEventModal
        v-if="mode === '1'"
        :dataList="editedDataList"
        :isLoading="isLoadingTable"
        v-model="isShowEventModel"
        v-bind="$attrs"
    />
    <InsuranceEditModal
        v-if="mode === '1'"
        :insId="insId"
        :dataList="editedDataList"
        :isLoading="isLoadingTable"
        :accountManCode="accountManCode"
        v-model="showEditModel"
        v-model:edited="editValueList"
    />
</template>

<script>
import _ from 'lodash'
import Button from '@/components/Button.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import InsuranceProduct from '@/containers/tia/InsuranceProduct.vue'
import InsuranceSales from '@/containers/tia/InsuranceSales.vue'
import InsuranceCommission from '@/containers/tia/InsuranceCommission.vue'
import InsuranceImage from '@/containers/tia/InsuranceImage.vue'
import InsuranceRemarkNote from '@/containers/tia/InsuranceRemarkNote.vue'
import InsuranceEditModal from '@/containers/tia/InsuranceEditModal.vue'
import InsuranceDetailEventModal from '@/containers/tia/InsuranceDetailEventModal.vue'
import {
    getTiaInsuranceDetailAPI,
    getCTWInsuranceDetailAPI
} from '@/assets/javascripts/api'
import smoothscroll from 'smoothscroll-polyfill'

export default {
    name: 'InsuranceDetail',
    components: {
        Button,
        ViewTitle,
        InsuranceProduct,
        InsuranceSales,
        InsuranceCommission,
        InsuranceImage,
        InsuranceRemarkNote,
        InsuranceDetailEventModal,
        InsuranceEditModal
    },
    computed: {
        insId: function () {
            return this.$route.params.id
        },
        manCode: function () {
            return this.$route.params.manCode
        },
        editedDataList: function () {
            const list = _.cloneDeep(this.dataList ?? {})
            if (Object.keys(this.editValueList).length > 0) {
                list.vehicleNumber = this.editValueList?.vehicleNumber || null
                list.propertyDueDate =
                    this.editValueList?.propertyDueDate || null
                list.note = this.editValueList?.note ?? list.note
            }

            return list
        },
        accountManCode: function () {
            return this.dataList.manCode
        },
        title() {
            return `保單明細${
                this.$route.params.resource == 1 ? `(自行上傳)` : ''
            }`
        },
        mode() {
            return this.$route.params.source
        }
    },
    methods: {
        backToInsurance() {
            this.$setGaEvent('backToInsurance', 'click-Button')
            const source = this.$route.params.source
            const setting = {
                1: {
                    name: 'TiaInsurance',
                    params: { mode: this.$route.params.source }
                },
                2: { name: 'CtwUpload', params: {} }
            }
            this.$router.push({
                name: setting[source].name,
                params: setting[source].params
            })
        }
    },
    watch: {
        page(newVal) {
            this.$setGaEvent(
                `toggle${this.$firstCapitalized(newVal)}(mobile)`,
                'click-Menu'
            )
            this.$nextTick(() => {
                smoothscroll.polyfill()
                const menu = this.$refs.menu
                const item = menu.querySelector('.active')
                const offsetPosition = item.offsetLeft
                let move = 0
                if (newVal === 'commission') {
                    move = offsetPosition / 2 - 28
                } else if (newVal === 'sales' || newVal === 'note') {
                    move = offsetPosition
                }
                menu.scrollTo({
                    left: move,
                    behavior: 'smooth'
                })
            })
        }
    },
    data() {
        return {
            page: 'product',
            pageSeq: ['product', 'sales', 'commission', 'image', 'note'],
            dataList: {},
            isShowEventModel: false,
            isLoadingTable: true,
            insServicesLength: 0,
            showEditModel: { isShow: false },
            editValueList: {}
        }
    },
    async created() {
        window.scrollTo({ top: 0 })
        this.$showLoading()
        try {
            const res = await {
                1: getTiaInsuranceDetailAPI,
                2: getCTWInsuranceDetailAPI
            }[this.$route.params.source](
                this.$route.params.id,
                this.$route.params.manCode
            )

            this.dataList = res.data
            this.insServicesLength = this.dataList['insServices']?.length
        } catch (error) {
            this.$showErrorMessage(
                '查無保單明細，將返回保單列表',
                '查無保單編號，請嘗試重新從保單查詢列表的「明細」按紐開啟此頁面，若有疑問請洽飛鴿',
                () => {
                    const source = this.$route.params.source
                    const name = source == 2 ? 'CtwUpload' : 'TiaInsurance'
                    this.$router.push({ name })
                }
            )
            this.dataList = {}
        } finally {
            this.editList = {}
            this.isLoadingTable = false
            this.$hideLoading()
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.table-container) {
    max-height: 100%;
    .table {
        max-height: 100%;
    }
}
.detail-viewtitle {
    @media screen and (max-width: 576px) {
        display: block;
        :deep(.main-header-word) {
            margin-bottom: 20px;
        }
    }
}
.menu-header {
    position: sticky;
    top: $mobile-header-height;
    display: none;
    width: calc(100% + 6px);
    margin: 0 0 0 -3px;
    background-color: $primary-grey;
    z-index: 1;
    @media screen and (max-width: 576px) {
        display: block;
    }
}

.menu-wrapper {
    @media screen and (max-width: 576px) {
        display: block;
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .menu {
        display: flex;
        justify-content: space-between;
        min-width: max-content;
        padding: 16px 15px 6px 15px;
        margin-bottom: 10px;
        box-sizing: border-box;
        border-bottom: 1px rgba(151, 151, 151, 0.4) solid;
        user-select: none;

        .menu-item {
            position: relative;
            display: flex;
            justify-content: center;
            font-size: 14px;
            color: $sixth-black;
            cursor: pointer;
            min-width: max-content;
            align-self: center;
            text-align: center;

            &.active:after {
                content: '';
                position: absolute;
                bottom: -13px;
                right: calc(50% - 20px);
                display: block;
                height: 3px;
                width: 40px;
                margin-bottom: 5px;
                background-color: $primary-red;
            }
            &:not(:last-child) {
                margin-right: 20px;
            }
            @media (min-width: 390px) and (max-width: 410px) {
                &:not(:last-child) {
                    margin-right: 40px;
                }
            }
            @media (min-width: 331px) and (max-width: 389px) {
                &:not(:last-child) {
                    margin-right: 32px;
                }
            }
            @media (min-width: 300px) and (max-width: 330px) {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
            @media screen and (max-width: 299px) {
                &:not(:last-child) {
                    margin-right: 30px;
                }
            }
        }
    }
}

.mobile-container {
    @media screen and (max-width: 576px) {
        display: flex;
        justify-content: space-between;
        overflow: auto;
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
        :deep(.content-viewtitle) {
            font-size: 20px;
            font-weight: 500;
        }
    }
    .mobile-page {
        @media screen and (max-width: 576px) {
            scroll-snap-align: start;
            flex-shrink: 0;
            width: calc(100vw - (100vw - 100%));
            display: none;
            &.active {
                display: block;
            }
        }
    }
}
</style>
